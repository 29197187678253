import "bootstrap/dist/css/bootstrap.min.css"
import "./App.css"
import { HashRouter, Routes, Route } from "react-router-dom"
import Auth from "./Auth"
import {Model} from "./Model"

function App() {
  return (
    <HashRouter basename="/"  future={{ v7_startTransition: true }}>
      <Routes>
        <Route path="/" element={<Auth />} />
        <Route path="/model" element={<Model />} />
      </Routes>
    </HashRouter>
  )
}

export default App