import React, { useState } from "react"
import { useNavigate } from "react-router-dom";

export default function (props) {
    const navigate = useNavigate();
    const [username, setUsername] = useState(null)
    const [password, setPassword] = useState(null)
    const [message, setMessage] = useState(false)

    const signIn = () => {
        if (username == 'demo' && password == 'googleearthforteams') {
            navigate("/model");
            setMessage(false)
        } else {
            setMessage(true)
            setInterval(() => {
                setMessage(false)
            }, 1500)
        }
    }

    return (
        <div className="Auth-form-container">
            <form className="Auth-form">
                <div className="Auth-form-content">
                    <h3 className="Auth-form-title">Sign In</h3>
                    <div className="form-group mt-3">
                        <label>Username</label>
                        <input
                            type="name"
                            className="form-control mt-1"
                            placeholder="Enter username"
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>
                    <div className="form-group mt-3">
                        <label>Password</label>
                        <input
                            type="password"
                            className="form-control mt-1"
                            placeholder="Enter password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    {message && (
                        <div className="form-group mt-3">
                            <div className="alert alert-danger" role="alert">
                                Username or Password not matched!
                            </div>
                        </div>
                    )}
                    <div className="d-grid gap-2 mt-3">
                        <button type="button" className="btn btn-primary" onClick={signIn}>
                            Sign In
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}