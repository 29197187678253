import React, { useMemo } from 'react';
import { extend } from '@react-three/fiber';
import { Html, Line, RoundedBox, Text } from '@react-three/drei';
import * as THREE from 'three';
import { SMTs, colorType } from '../constant';
import { useControls } from "leva";

extend({ Line });

export const PolarGridHelper = ({ radius = 10, divisions = 16, circleDivision = 3, color = '#000000', clonedSMTs }) => {
    const title = useMemo(() => {
        let titleLeva = {}
        clonedSMTs.map((SMT, index) => {
            titleLeva[`department${index+1}`] = SMT.department
        })
        return titleLeva
    }, [])
    const depTitle = useControls('Department Title', title)

    const lines = [];
    const headLineTexts = [];
    const departmentTitle = [];
    const goalRing = [];
    const headlineRadius = radius + 0.8;
    const smtlineRadius = radius / 2;
    const goalRadius = radius - 0.8;
    // Generate concentric circles
    for (let i = 1; i <= circleDivision; i++) {
        const circleRadius = (radius / circleDivision) * i;
        const points = [];
        for (let j = 0; j <= 360; j += 360 / 128) { // Increase the last value for more detail
            const rad = THREE.MathUtils.degToRad(j);
            points.push(new THREE.Vector3(Math.cos(rad) * circleRadius, 0, Math.sin(rad) * circleRadius));
        }
        lines.push(<Line key={`circle-${i}`} points={points} color={color} />);
    }

    // Generate radial lines
    for (let i = 0; i < divisions; i++) {
        const angle = (2 * Math.PI / divisions) * i;
        const x = Math.cos(angle) * radius;
        const z = Math.sin(angle) * radius;
        const linePoints = [new THREE.Vector3(0, 0, 0), new THREE.Vector3(x, 0, z)];
        lines.push(<Line key={`radial-${i}`} lineWidth={5} points={linePoints} color={color} />);
    }

    for (let i = 0; i < divisions; i++) {
        const angle = (2 * Math.PI / divisions) * i + Math.PI / divisions;
        const headlineX = Math.cos(angle) * headlineRadius;
        const headlineZ = Math.sin(angle) * headlineRadius;

        const departTitleX = Math.cos(angle) * smtlineRadius;
        const departTitleZ = Math.sin(angle) * smtlineRadius;

        headLineTexts.push({
            rotation: [-Math.PI / 2, 0, -angle + 4 * Math.PI / divisions],
            overflowWrap: "break-word",
            fontSize: 0.5,
            position: [headlineX, 0, headlineZ],
            anchorX: "center",
            anchorY: "middle",
            text: clonedSMTs[i].headline
        }
        )

        departmentTitle.push({
            rotation: [-Math.PI / 2, 0, -angle + 4 * Math.PI / divisions],
            overflowWrap: "break-word",
            fontSize: 0.8,
            position: [departTitleX, 0.0, departTitleZ],
            anchorX: "center",
            anchorY: "middle",
            text: depTitle[`department${i+1}`]
        }
        )

    }
    let goalDivisionID = 0;
    for (let i = 0; i < divisions; i++) {
        let goalColorArray = clonedSMTs[i].goal;
        for (let j = 0; j < 3; j++) {
            goalDivisionID++
            const goalColorMap = {
                r: '#' + colorType.red,
                y: '#' + colorType.yellow,
                g: '#' + colorType.green
            };
            const goalTitle = ["Goal1", "Goal2", "Goal3"]

            const goalColor = goalColorMap[goalColorArray[j]];

            const angle = (2 * Math.PI / (divisions * 3)) * goalDivisionID - Math.PI / (divisions * 3);
            const goalX = Math.cos(angle) * goalRadius;
            const goalY = Math.sin(angle) * goalRadius;
            goalRing.push({
                goalTitle: goalTitle[j],
                goalColor,
                rotation: [-Math.PI / 2, 0, -angle + 4 * Math.PI / divisions],
                position: [goalX, 0.0, goalY],
            })
        }
    }

    return <>
        <group>
            {lines}
            {headLineTexts.map((item, index) => {
                return (
                    <Text
                        {...item}
                    >{item.text}
                    </Text>)
            })}
            {departmentTitle.map((item) => {
                return (
                    <Text
                        {...item}
                    >{item.text}
                    </Text>)
            })}
            {/* {goalRing.map((item) => {
                return (
                    <Html zIndexRange={[16777271, 0]} transform occlude {...item}>
                        <div class='goal'>
                            <p class='goal-text'>Goal1</p>
                        </div>
                    </Html>)
            })} */}
            {goalRing.map((item) => {
                return (
                    <group >
                        <RoundedBox
                            {...item}
                            args={[4, 1, 0.1]} // Width, height, depth. Default is [1, 1, 1]
                            radius={0.05} // Radius of the rounded corners. Default is 0.05
                            smoothness={4} // The number of curve segments. Default is 4
                            bevelSegments={4} // The number of bevel segments. Default is 4, setting it to 0 removes the bevel, as a result the texture is applied to the whole geometry.
                            creaseAngle={0.4} // Smooth normals everywhere except faces that meet at an angle greater than the crease angle
                        >
                            <meshStandardMaterial color={item.goalColor} />
                        </RoundedBox>
                        <group>
                            <RoundedBox
                                {...item}
                                args={[3.8, 0.8, 0.11]} // Width, height, depth. Default is [1, 1, 1]
                                radius={0.05} // Radius of the rounded corners. Default is 0.05
                                smoothness={4} // The number of curve segments. Default is 4
                                bevelSegments={4} // The number of bevel segments. Default is 4, setting it to 0 removes the bevel, as a result the texture is applied to the whole geometry.
                                creaseAngle={0.4} // Smooth normals everywhere except faces that meet at an angle greater than the crease angle
                            >
                                <meshStandardMaterial color={"#222222"} />
                            </RoundedBox>
                            <Text fontSize={0.5} {...item} position={[item.position[0], item.position[1] + 0.1, item.position[2]]} >{item.goalTitle}</Text>
                        </group>
                    </group>
                )
            })}
            <mesh position={[0, -0.28, 0]}>
                <cylinderGeometry args={[20, 20, 0.5, 128]} />
                {/* <circleGeometry args={[20, 128]} /> */}
                <meshStandardMaterial roughness={0.5} metalness={0.5} color={'#4F5857'} transparent />
            </mesh>
        </group>
    </>;
};
